* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
}

html {
    scroll-behavior: smooth;
}

#header {
    background-color: rgb(36, 36, 36);
    color: white;
    animation: fadeInDown 1s;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    overflow: scroll;

    .menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 50px;
        height: 60px;

        .menuBar {
            display: none;
        }

        .logo {
            h2 {
                color: orangered;
            }
        }

        ul {
            display: flex;
            gap: 40px;


            li {
                list-style: none;
                font-size: 18px;
                font-weight: 500;
                cursor: pointer;

                a {
                    text-decoration: none;
                    color: rgb(160, 160, 160);
                }


                &::after {
                    content: "";
                    height: 2px;
                    width: 0;
                    background-color: orangered;
                    transition: all .5s;
                    display: block;
                    margin-top: 2px;
                }

                &:hover {
                    &::after {
                        content: "";
                        width: 100%;
                    }
                }

            }
        }
    }

}

#trangChu {
    .content {
        padding: 80px 100px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .contentItem {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: calc((100% - 20px)/2);
            // height: calc((100vh - 200px - 90px));
            // background-color: rgba(0, 0, 0, 0.062);
            // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

            padding: 50px;

            h1 {
                color: orangered;
                padding: 0 20px;
                font-size: 60px;

            }

            p {
                padding: 20px 10px 0;
                font-size: 18px;
                color: #555555;

                i {
                    margin-left: 30px;
                }
            }

            a {

                button {
                    padding: 10px 20px;
                    border: none;
                    background-color: orangered;
                    font-size: 18px;
                    border-radius: 15px 0 15px 0;
                    margin: 20px auto;
                    cursor: pointer;
                    transition: all .5s;


                    &:hover {
                        background-color: red;
                    }


                    text-decoration: none;
                    color: white;

                }
            }

            img {
                width: 100%;
                height: 100%;
                border-radius: 50px 0 50px 0;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                filter: grayscale(100%);

            }
        }

    }
}

#sanPham {
    padding: 100px 100px 100px;

    h1 {
        margin-bottom: 10px;
        color: orangered;

        &.bepGas {
            margin-top: 30px;
        }
    }



    .content {
        // padding: 160px 100px 100px;
        display: flex;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;


        .sanPhamItem {
            width: calc((100% - 60px) / 4);
            height: 600px;
            // background-color: orange;
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;

            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

            img {
                width: 100%;
                height: 100%;
                padding: 30px
            }



            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.3);
                transition: all .5s;
                z-index: 1;
                opacity: 0;
                transform: translateX(100%);

                h1 {
                    transition: all .5s;
                    text-align: center;
                    color: rgb(60, 60, 60);
                    font-size: 20px;
                    font-weight: 500;
                    margin-top: 30px;

                }

                p {
                    color: white;
                    text-align: center;
                }

                a {
                    color: white;
                    text-decoration: none;
                    text-align: center;
                    display: block;
                    width: 160px;
                    padding: 10px;
                    border: 1px solid white;
                    border-radius: 10px;
                    margin: 20px auto 0;
                    transition: all .5s;

                    &:hover {
                        // color: orangered;
                    }
                }

                h2 {
                    transition: all .5s;
                    text-align: center;
                    color: rgb(60, 60, 60);
                    position: absolute;
                    bottom: 20px;
                    right: 20px;
                    opacity: 0;
                    font-weight: 500;

                }

            }

            &:hover {
                .overlay {
                    transform: translateX(0);
                    opacity: 1;


                }


                h1 {
                    color: white;
                    z-index: 2;
                    scale: 1.1;
                }

                h2 {
                    z-index: 2;

                    opacity: 1;
                    color: white;
                }

            }

            &:nth-child(even) {

                .overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(0, 0, 0, 0.2);
                    transition: all .5s;
                    z-index: 1;
                    opacity: 0;
                    transform: translateX(-100%);


                }

                &:hover {
                    .overlay {
                        transform: translateX(0);
                        opacity: 1;
                    }


                }

            }


        }


        .sanPhamBepItem {
            width: calc((100% - 60px) / 4);
            height: 300px;
            background-color: rgb(255, 116, 51);
            cursor: pointer;
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                height: 100%;
            }

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.2);
                transform: translateY(-100%);
                transition: all .5s;
                z-index: 1;
                opacity: 0;

                h1 {
                    transition: all .5s;
                    text-align: center;
                    color: rgb(60, 60, 60);
                    font-size: 20px;
                    font-weight: 400;
                    margin-top: 30px;
                }

                p {
                    color: white;
                    text-align: center;
                }


                h2 {
                    transition: all .5s;
                    text-align: center;
                    color: rgb(60, 60, 60);
                    position: absolute;
                    bottom: 20px;
                    right: 20px;
                    opacity: 0;
                    font-weight: 500;

                }


            }




            &:hover {
                .overlay {
                    transform: translateY(0);
                    opacity: 1;


                }

                h1 {
                    color: white;
                    z-index: 2;
                    scale: 1.1;
                }

                h2 {
                    z-index: 2;
                    opacity: 1;
                    color: white;

                }

            }


            &:nth-child(even) {
                .overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(0, 0, 0, 0.2);
                    transform: translateY(100%);
                    transition: all .5s;
                    z-index: 1;
                    opacity: 0;
                }

                &:hover {
                    .overlay {
                        transform: translateY(0);
                        opacity: 1;


                    }


                }
            }

        }
    }
}

#about {
    height: calc(100vh / 2);

    background-image: url('../public/img/banner1.jpg');
    background-size: cover;
    /* hình ảnh sẽ tự động được phóng to hoặc thu nhỏ để vừa với phần tử */
    background-position: center;
    /* căn giữa hình ảnh */
    background-repeat: no-repeat;
    /* không lặp lại hình ảnh */
    background-attachment: fixed;
    filter: grayscale(100%);
    /* chuyển hình ảnh thành đen trắng */
    position: relative;
    /* đặt vị trí tương đối */
    z-index: 0;
    /* đặt z-index để không nằm trên cùng */
    padding-top: 90px;

}


#contact {
    height: 100vh;
    padding: 100px;

    h1 {
        margin-bottom: 10px;
    }
}

#footer {
    // height: 50px;
    text-align: center;
    background-color: rgb(36, 36, 36);
    color: rgb(160, 160, 160);
    padding: 20px;

    span {
        font-size: 10px;
    }
}

.fadeInDown {
    animation: fadeInRight 1s;

}

.fadeInUp {
    animation: fadeInUp 2s;

}

.fadeInRight {
    animation: fadeInRight 1s;

}

.fadeInLeft {
    animation: fadeInLeft 1s;

}


@media screen and (max-width: 576px) {
    #header {
        .menu {
            .menuBar {
                display: block;

                i {
                    font-size: 18px;
                }
            }

            ul {
                display: block;
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 40px 20px;
                background-color: rgb(245, 245, 245);
                // color: white;
                transform: translateX(-100%);
                transition: all .3s;
                z-index: 2000;

                li {
                    margin: 10px 0;

                    a {
                        color: black;
                    }
                }

                button.close {
                    position: absolute;
                    top: 15px;
                    right: 20px;
                    border: none;
                    background-color: transparent;
                    color: orangered;
                    font-size: 20px;
                    cursor: pointer;
                }
            }
        }
    }

    #trangChu {
        // margin-top: 90px;

        .content {
            padding: 50px 10px 10px;
            flex-direction: column;
            gap: 30px;

            .contentItem {
                width: 100%;
                padding: 0;

                h1 {
                    margin-top: 30px;
                    font-size: 42px;
                    text-align: center;
                }
            }

            button {
                margin: 0 auto;
            }

        }
    }

    #sanPham {
        padding: 100px 10px 10px;


        .content {
            padding: 10px 0;
            flex-direction: column;
            gap: 10px;


            .sanPhamItem {
                width: 100%;
                // background-color: orange;
            }

            .sanPhamBepItem {
                width: 100%;
                height: 300px;
                background-color: orange;
            }
        }
    }

    #about {
        background-size: contain;
        background-attachment: scroll;
    }


    #contact {
        padding: 100px 0px 0px;
        height: auto;

        h1 {
            margin-left: 10px;
        }

    }


}

@keyframes fadeInDown {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0x);

    }

}

@keyframes fadeInUp {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }

    50% {
        transform: translateY(100%);
        opacity: 0;
    }


    100% {
        transform: translateY(0);
        opacity: 1;

    }

}

@keyframes fadeInRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0x);

    }

}

@keyframes fadeInLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0x);

    }

}